import React, { useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "../Registeration/Registration.css";

const RegistrationForm = () => {
  // State to store form data
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    university: "",
    graduationYear: "",
    additionalInfo: "",
  });

  // State to manage form submission status
  const [submitted, setSubmitted] = useState(false);

  // Handle change for input fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission - Use a traditional form action with mailto:
  const handleSubmit = (e) => {
    e.preventDefault();

    const subject = `Training Registration - ${formData.firstName} ${formData.lastName}`;
    const body = `
      First Name: ${formData.firstName}
      Last Name: ${formData.lastName}
      Email: ${formData.email}
      Phone: ${formData.phone}
      University: ${formData.university}
      Major: ${formData.major}
      Graduation Year: ${formData.graduationYear}
      Additional Information: ${formData.additionalInfo}
    `;

    // Construct the mailto URL
    const mailtoLink = `mailto:barnawi.amer@yahoo.com?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    // Use window.open to try and open the default email client
    window.open(mailtoLink);

    // Clear the form
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      university: "",
      major: "",
      graduationYear: "",
      additionalInfo: "",
    });

    setSubmitted(true);
  };

  return (
    <>
      <Header />
      <div className="form-container">
        <h2>Student Training Registration</h2>
        {submitted && (
          <div className="success-message">
            Thank you for registering! We will get in touch soon.
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="input-lable-container">
            <div className="label">
              <label>First Name:</label>
            </div>
            <div className="input">
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="input-lable-container">
            <div className="label">
              <label>Last Name:</label>
            </div>
            <div className="input">
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="input-lable-container">
            <div className="label">
              <label>Email:</label>
            </div>
            <div className="input">
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="input-lable-container">
            <div className="label">
              <label>Phone:</label>
            </div>
            <div className="input">
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="input-lable-container">
            <div className="label">
              <label>University:</label>
            </div>
            <div className="input">
              <input
                type="text"
                name="university"
                value={formData.university}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="input-lable-container">
            <div className="label">
              <label>Graduation Year:</label>
            </div>
            <div className="input">
              <input
                type="number"
                name="graduationYear"
                value={formData.graduationYear}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="textarea-lable-container">
            <div className="label">
              <label>Additional Information:</label>
            </div>
            <div className="textarea">
              <textarea
                name="additionalInfo"
                value={formData.additionalInfo}
                onChange={handleChange}
                rows="4"
              />
            </div>
          </div>

          <button type="submit" className="submit-button ">
            Submit
          </button>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default RegistrationForm;
