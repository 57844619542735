import React, { useState } from "react";
import "../Navbar/Navbar.css";
import { NavLink } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import { BsInfoCircleFill } from "react-icons/bs";
import { RiPresentationFill } from "react-icons/ri";
import { TfiWrite } from "react-icons/tfi";
import { MdContactMail } from "react-icons/md";

function Navbar() {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const navbarDetails = [
    {
      name: <FaHome className="icon" />,
      hoverd: "Home",
      link: "/",
    },
    {
      name: <BsInfoCircleFill className="icon" />,
      hoverd: "about",
      link: "/about",
    },
    {
      name: <RiPresentationFill className="icon" />,
      hoverd: "Courses",
      link: "/course",
    },
    {
      name: <TfiWrite className="icon" />,
      hoverd: "Register",
      link: "/register",
    },
    {
      name: <MdContactMail className="icon" />,
      hoverd: "Contact",
      link: "/contact",
    },
  ];

  return (
    <div className="navbar-container">
      {navbarDetails.map((button, index) => (
        <NavLink
          index={index}
          to={button.link}
          className="navlink"
          onMouseEnter={() => setHoveredIndex(index)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <div className="button">
            {hoveredIndex === index ? button.hoverd : button.name}
          </div>
        </NavLink>
      ))}
    </div>
  );
}

export default Navbar;
