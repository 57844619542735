import React from "react";
import "../Course/Course.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import courseImage from "../../assest/photo/course-image.png";

function Course() {
  return (
    <div>
      <Header />
      <div className="courses-container">
        <div className="course-imgs-container">
          <div className="course-img-container">
            <img src={courseImage} alt="English Description" />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Course;
