import React from "react";
import "../Contact/Contact.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

function Contact() {
  return (
    <>
      <Header />
      <div className="contact-container">
        <h2>Contact Information</h2>
        <div>
          <p>
            <span>Telephone:</span> +31 649 127 219
          </p>
          <p>
            <span>email:</span> barnawi.amer@yahoo.com
          </p>
          <p>
            <span>Address:</span> Van Baerlestraat 122 - Den Haag
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Contact;
