import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./Component/HomePage/HomePage";
import Contact from "./Component/Contact/Contact";
import Course from "./Component/Course/Course";
import RegistrationForm from "./Component/Registeration/Registration";
import About from "./Component/About/About";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="contact" element={<Contact />} />
          <Route path="course" element={<Course />} />
          <Route path="/register" element={<RegistrationForm />} />
          <Route path="/about" element={<About />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
