import React from "react";
import "../About/About.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

function About() {
  return (
    <>
      <Header />
      <div className="about-container">
        <p>
          <span>Eye On The Future</span> Empowering New Beginnings through
          Education, Consulting, and Innovation Located in The Hague, Eye On The
          Future is a versatile educational and consulting company dedicated to
          empowering individuals and organizations through training, skill
          development, and expert guidance in today’s fast-evolving digital and
          technical landscape. We specialize in web development, marketing,
          professional growth, and more advanced consulting services, delivering
          solutions that build confidence, competence, and career readiness.
          <br />
          <br />
          At <span>Eye On The Future</span>, we are especially committed to
          supporting refugees as they begin new careers. Through our free
          training programs, we equip refugees with foundational skills in web
          development, alongside English language instruction and essential
          professional development skills such as CV building, interview
          techniques, and personal growth guidance. Our on-site training
          facilities in The Hague host regular classes, while we also offer
          traineeships and fellowships, both locally and internationally, to
          broaden opportunities for new and aspiring developers.
          <br />
          <br />
          In addition to our educational programs,{" "}
          <span>Eye On The Future</span> provides expert consulting services in
          software development, technology, management, and even specialized
          fields like physics and energy solutions. Our team offers tailored
          problem-solving and advisory services to help companies tackle
          challenges in technology implementation, energy management, and
          digital transformation.
          <br />
          <br />
          Forindividuals and organizations alike, our paid web development
          courses and customized consulting packages allow us to bring both
          technical knowledge and strategic insights to each client. Our team’s
          diverse expertise ensures that we deliver not only the hard skills
          necessary for success but also the soft skills essential for long-term
          growth.
          <br />
          <br />
          With <span>Eye On The Future</span>, we empower individuals and
          organizations to overcome challenges, enhance their capabilities, and
          find innovative solutions in technology, management, and beyond.
          Through our refugee support programs and comprehensive consulting
          services, our mission is to foster a stronger, more inclusive
          workforce and create pathways to a brighter, sustainable future.
          <br />
          <br />
          Amer Barnawi - Company Owner.
        </p>
      </div>
      <Footer />
    </>
  );
}

export default About;
