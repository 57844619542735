import React from "react";
import Footer from "../Footer/Footer";
import "../HomePage/HomePage.css";
import companyLogo from "../../assest/logo/company-logo.png";
import goldAtomLogo from "../../assest/logo/gold-atom-logo.png";
import homePageTitle from "../../assest/photo/home-page-title.png";
import peopleShining from "../../assest/photo/people-shining.jpg";
import qualityText from "../../assest/photo/quality-text.png";
import textByAmer from "../../assest/photo/text-by-amer.png";
import { NavLink } from "react-router-dom";

function HomePage() {
  return (
    <div className="home-page">
      <div className="title-home-page">
        <div className="quality-text">
          <img src={qualityText} alt="High Quality Education" />
        </div>
        <div className="title">
          <img
            src={homePageTitle}
            alt="Learn .. with the new way of teaching."
          />
        </div>
        <div className="navbar">
          <div className="line"></div>
          <NavLink className="navlink">Home</NavLink>
          <NavLink to="/about" className="navlink">
            About
          </NavLink>
          <NavLink to="/course" className="navlink">
            Courses
          </NavLink>
          <NavLink to="/register" className="navlink">
            Register
          </NavLink>
          <NavLink to="/contact" className="navlink">
            Contact
          </NavLink>
        </div>
      </div>
      <div className="content-home-page">
        <div className="gold-atom">
          <img src={goldAtomLogo} alt="Atom Logo" />
        </div>
        <div className="text-by-amer">
          <img
            src={textByAmer}
            alt="Your life is looks like the tree, when you learn, it grow up, to spread the oxygen in our life."
          />
        </div>
        <div className="empty-area"></div>
      </div>

      <div className="banner-home-page">
        <div className="photo">
          <img src={peopleShining} alt="People in the mountens" />
        </div>
        <div className="company-logo">
          <img src={companyLogo} alt="Eye On The Future Academy" />
        </div>
      </div>
      <Footer className="footer" />
    </div>
  );
}

export default HomePage;
