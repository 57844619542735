import React from "react";
import Navbar from "../Navbar/Navbar";
import "../Header/Header.css";

import companyLogo from "../../assest/logo/company-logo.png";
import atom from "../../assest/photo/atom.png";

function Header() {
  return (
    <div className="header-container">
      <div className="empty">
        <img src={atom} alt="Gold Atom" />
      </div>
      <div className="company-logo">
        <img src={companyLogo} alt="Eye On The Future Academy" />
      </div>
      <div className="navbar">
        <Navbar className="navbar-container" />
      </div>
    </div>
  );
}

export default Header;
