import React from "react";
import "../Footer/Footer.css";
import { MdEmail } from "react-icons/md";
import { BsGithub, BsLinkedin } from "react-icons/bs";

function Footer() {
  return (
    <div className="footer">
      <div className="contact-details">
        <a
          target="_blank"
          href="mailto:barnawi.amer@yahoo.com"
          rel="noopener noreferrer"
        >
          <MdEmail className="contact" />
        </a>
        <a
          target="_blank"
          href="https://github.com/amerbarnawi"
          rel="noopener noreferrer"
        >
          <BsGithub className="contact" />
        </a>
        <a
          target="_blank"
          href="https://www.linkedin.com/company/eotfa"
          rel="noopener noreferrer"
        >
          <BsLinkedin className="contact" />
        </a>
      </div>
      <p>
        &copy;2024{" "}
        <a
          className="eotf-website"
          target="_blank"
          href="https://www.eye-on-the-future.com"
          rel="noopener noreferrer"
        >
          <span>Eye On The Future</span>
        </a>
        , All rights reserved.
      </p>
    </div>
  );
}

export default Footer;
